<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <!--      <el-form-item label="用户id 关联tc_emp中的工号work_id" prop="emId">-->
      <!--        <el-input v-model="dataForm.emId" :disabled="disabled" placeholder="用户id 关联tc_emp中的工号work_id"></el-input>-->
      <!--      </el-form-item>-->
      <el-form-item label="员工名字" prop="emName">
        <el-input
          v-model="dataForm.emName"
          :disabled="disabled"
          placeholder="员工名字，冗余字段"
        ></el-input>
      </el-form-item>
      <el-form-item label="积分量" prop="exchangPoints">
        <el-input
          v-model="dataForm.exchangPoints"
          :disabled="disabled"
          placeholder="需转换的积分量"
        ></el-input>
      </el-form-item>
      <el-form-item label="转换比率" prop="rate">
        <el-input
          v-model="dataForm.rate"
          :disabled="disabled"
          placeholder="获取转换比率"
        ></el-input>
      </el-form-item>
      <el-form-item label="金额" prop="exchangMoney">
        <el-input
          v-model="dataForm.exchangMoney"
          :disabled="disabled"
          placeholder="已转换的金额"
        ></el-input>
      </el-form-item>
      <el-form-item label="转换时间" prop="exchangTime">
        <el-input
          v-model="dataForm.exchangTime"
          :disabled="disabled"
          placeholder="转换金额时间"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { keys, pick } from 'lodash';

export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        emId: '',
        emName: '',
        exchangPoints: '',
        rate: '',
        exchangMoney: '',
        exchangTime: '',
      },
      dataRule: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      },
    };
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/pointschangedlog/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.pointschangedlog, keys(this.dataForm));
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/pointschangedlog/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
